import React, { useState } from 'react';
import { DynamoDB } from 'aws-sdk';

type TitleProps = {
    readonly dynamodb: DynamoDB;
}

type Title = {
    readonly eBookID: string;
    readonly cmsID: string;
    readonly name?: string;
}

export const TitleIDSearchFromEBook = (props: TitleProps) => {
    const [serviceID, setServiceID] = useState('');
    const [ebookIDs, setEBookIDs] = useState('');
    const [titles, setTitles] = useState<Title[]>([]);
    const search = async () => {
        if (serviceID === '' || ebookIDs === '') {
            return;
        }
        const keys = ebookIDs.split(',').map(id => {
            return {
                'ID': { S: id },
                'Type': { S: 'dist_' + serviceID + '_title' },
            }
        });
        const res = await props.dynamodb.batchGetItem({
            RequestItems: {
                'Products': {
                    Keys: keys,
                }
            }
        }).promise();
        if (res.Responses === undefined) {
            return;
        }
        setTitles(res.Responses['Products'].map(data => {
            return {
                eBookID: data.ID['S'] as string,
                cmsID: data.CMSID['S'] as string,
                name: data.TitleName['S'] as string,
            };
        }));
    }
    let titleList;
    if (titles.length === 0) {
        titleList = <li>該当なし</li>
    } else {
        titleList = titles.map(title =>
            <li key={title.eBookID}>
                eBook={title.eBookID}, MDCMS-SD={title.cmsID}, タイトル={title.name}
            </li>
        );
    }
    return (
        <>
            <p>eBookのタイトルIDからMDCMS-SDのタイトルIDを検索します。</p>
            サービスID(数字): <input type='text' onChange={e => setServiceID(e.target.value)} />
            <br />
            eBookタイトルID(カンマ区切り複数): <input type='text' onChange={e => setEBookIDs(e.target.value)} />
            <br />
            <button onClick={search}>検索</button>
            <ul>
                {titleList}
            </ul>
        </>
    );
}

export const TitleIDSearchFromCMS = (props: TitleProps) => {
    const [serviceID, setServiceID] = useState('');
    const [ebookIDs, setEBookIDs] = useState('');
    const [titles, setTitles] = useState<Title[]>([]);
    const search = async () => {
        if (serviceID === '' || ebookIDs === '') {
            return;
        }
        const result = [] as Title[];
        const ids = ebookIDs.split(',');
        for (let i = 0; i < ids.length; i++) {
            const id = ids[i];
            const res = await props.dynamodb.query({
                TableName: 'Products',
                IndexName: 'CMSIDIndex',
                ExpressionAttributeNames: {
                    '#type' : 'Type' // TypeがDynamoDBの予約語でそのまま使うとエラーになる
                },
                ExpressionAttributeValues: {
                    ':id': { S: id },
                    ':type': { S: 'dist_' + serviceID + '_title' },
                },
                KeyConditionExpression: 'CMSID = :id and #type = :type'
            }).promise();
            if (res.Items?.length === 0) {
                continue;
            }
            const item = res.Items![0];
            result.push({
                eBookID: item.ID['S'] as string,
                cmsID: item.CMSID['S'] as string,
            });
        }
        setTitles(result);
    }
    let titleList;
    if (titles.length === 0) {
        titleList = <li>該当なし</li>
    } else {
        titleList = titles.map(title =>
            <li key={title.eBookID}>
                MDCMS-SD={title.cmsID}, eBook={title.eBookID}
            </li>
        );
    }
    return (
        <>
            <p>MDCMS-SDのタイトルIDからeBookのタイトルIDを検索します。</p>
            サービスID(数字): <input type='text' onChange={e => setServiceID(e.target.value)} />
            <br />
            MDCMS-SDタイトルID(カンマ区切り複数): <input type='text' onChange={e => setEBookIDs(e.target.value)} />
            <br />
            <button onClick={search}>検索</button>
            <ul>
                {titleList}
            </ul>
        </>
    );
}
