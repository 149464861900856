import React from 'react';
import Amplify from 'aws-amplify';
import AWS, { DynamoDB } from 'aws-sdk';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';

import { AmplifyAuthCredentials } from './aws/credentials';
import { Console } from './console';

const region = process.env.REACT_APP_AWS_REGION!;

Amplify.configure({
  Auth: {
      region: region,
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID!,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID!,
  },
});

function App() {
  const provider = new AWS.CredentialProviderChain([() => new AmplifyAuthCredentials()]);
  const dynamodb = new DynamoDB({ credentialProvider: provider, region: region });
  return (
    <>
      <AmplifySignOut />
      <Console dynamodb={dynamodb} />
    </>
  );
}

export default withAuthenticator(App);
