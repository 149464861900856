import React, { useState } from 'react';
import { DynamoDB } from 'aws-sdk';

type Props = {
    readonly dynamodb: DynamoDB;
}

type Content = {
    readonly distID: string;
    readonly cmsID: string;
    readonly contentID?: string;
    readonly contentFormatID?: string;
    readonly supplyType?: string;
}

export const PermitIDSearchFromDistributionID = (props: Props) => {
    const [serviceID, setServiceID] = useState('');
    const [distIDs, setDistIDs] = useState('');
    const [contents, setContents] = useState<Content[]>([]);
    const search = async () => {
        if (serviceID === '' || distIDs === '') {
            return;
        }
        const keys = distIDs.split(',').map(id => {
            return {
                'ID': { S: id },
                'Type': { S: 'dist_' + serviceID + '_content' },
            }
        });
        const res = await props.dynamodb.batchGetItem({
            RequestItems: {
                'Products': {
                    Keys: keys,
                }
            }
        }).promise();
        if (res.Responses === undefined) {
            return;
        }
        setContents(res.Responses['Products'].map(data => {
            return {
                distID: data.ID['S'] as string,
                cmsID: data.CMSID['S'] as string,
                contentID: data.ContentID['S'] as string,
                contentFormatID: data.ContentFormatID['S'] as string,
            };
        }));
    }
    let list;
    if (contents.length === 0) {
        list = <li>該当なし</li>
    } else {
        list = contents.map(content =>
            <li key={content.distID}>
                ディストリビューションID={content.distID}, 配信許諾ID={content.cmsID}, 20桁コンテンツID={content.contentID}, 26桁コンテンツフォーマットID={content.contentFormatID}
            </li>
        );
    }
    return (
        <>
            <p>配信サービスのディストリビューションIDからMDCMS-SDの配信許諾IDを検索します。</p>
            サービスID(数字): <input type='text' onChange={e => setServiceID(e.target.value)} />
            <br />
            ディストリビューションID(カンマ区切り複数): <input type='text' onChange={e => setDistIDs(e.target.value)} />
            <br />
            <button onClick={search}>検索</button>
            <ul>
                {list}
            </ul>
        </>
    );
}

export const DistributionIDSearchFromPermitID = (props: Props) => {
    const [serviceID, setServiceID] = useState('');
    const [distIDs, setDistIDs] = useState('');
    const [contents, setContents] = useState<Content[]>([]);
    const search = async () => {
        if (serviceID === '' || distIDs === '') {
            return;
        }
        const result = [] as Content[];
        const ids = distIDs.split(',');
        for (let i = 0; i < ids.length; i++) {
            const id = ids[i];
            const res = await props.dynamodb.query({
                TableName: 'Products',
                IndexName: 'CMSIDIndex',
                ExpressionAttributeNames: {
                    '#type': 'Type' // TypeがDynamoDBの予約語でそのまま使うとエラーになる
                },
                ExpressionAttributeValues: {
                    ':id': { S: id },
                    ':type': { S: 'dist_' + serviceID + '_content' },
                },
                KeyConditionExpression: 'CMSID = :id and #type = :type'
            }).promise();
            if (res.Items?.length === 0) {
                continue;
            }
            const item = res.Items![0];
            result.push({
                distID: item.ID['S'] as string,
                cmsID: item.CMSID['S'] as string,
            });
        }
        setContents(result);
    }
    let list;
    if (contents.length === 0) {
        list = <li>該当なし</li>
    } else {
        list = contents.map(content =>
            <li key={content.distID}>
                配信許諾ID={content.cmsID}, ディストリビューションID={content.distID}
            </li>
        );
    }
    return (
        <>
            <p>MDCMS-SDの配信許諾IDから配信サービスのディストリビューションIDを検索します。</p>
            サービスID(数字): <input type='text' onChange={e => setServiceID(e.target.value)} />
            <br />
            配信許諾ID(カンマ区切り複数): <input type='text' onChange={e => setDistIDs(e.target.value)} />
            <br />
            <button onClick={search}>検索</button>
            <ul>
                {list}
            </ul>
        </>
    );
}


export const PermitIDSearchFromContentID = (props: Props) => {
    const [serviceID, setServiceID] = useState('');
    const [distIDs, setDistIDs] = useState('');
    const [contents, setContents] = useState<Content[]>([]);
    const search = async () => {
        if (serviceID === '' || distIDs === '') {
            return;
        }
        const result = [] as Content[];
        const ids = distIDs.split(',');
        for (let i = 0; i < ids.length; i++) {
            const id = ids[i];
            const res = await props.dynamodb.query({
                TableName: 'Products',
                IndexName: 'ContentIDIndex',
                ExpressionAttributeNames: {
                    '#type': 'Type' // TypeがDynamoDBの予約語でそのまま使うとエラーになる
                },
                ExpressionAttributeValues: {
                    ':id': { S: id },
                    ':type': { S: 'dist_' + serviceID + '_content' },
                },
                KeyConditionExpression: 'ContentID = :id and #type = :type'
            }).promise();
            if (res.Items?.length === 0) {
                continue;
            }
            res.Items!.forEach(item => {
                result.push({
                    distID: item.ID['S'] as string,
                    contentID: item.ContentID['S'] as string,
                    cmsID: item.CMSID['S'] as string,
                    supplyType: item.SupplyType['S'] as string,
                });
            });
        }
        setContents(result);
    }
    let list;
    if (contents.length === 0) {
        list = <li>該当なし</li>
    } else {
        list = contents.map(content =>
            <li key={content.distID + content.supplyType}>
                20桁コンテンツID={content.contentID}, 配信許諾ID={content.cmsID}, ディストリビューションID={content.distID}, 本編/サンプル={content.supplyType === '1' ? '本編' : 'サンプル'}
            </li>
        );
    }
    return (
        <>
            <p>eBookの20桁コンテンツIDからMDCMS-SDの配信許諾IDを検索します。</p>
            サービスID(数字): <input type='text' onChange={e => setServiceID(e.target.value)} />
            <br />
            20桁コンテンツID(カンマ区切り複数): <input type='text' onChange={e => setDistIDs(e.target.value)} />
            <br />
            <button onClick={search}>検索</button>
            <ul>
                {list}
            </ul>
        </>
    );
}
