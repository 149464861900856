import React, { useState } from "react";
import { DynamoDB } from "aws-sdk";

type Props = {
  dynamodb: DynamoDB;
};

const CampaignCSV = (props: Props) => {
  const [serviceID, setServiceID] = useState("");
  const [fileState, setFileState] = useState(false);
  const [titleID, setTitleID] = useState([""]);
  const [contentID, setContentID] = useState([""]);

  const setData = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.files || !e.currentTarget.files.length) {
      setFileState(false);
      return;
    }
    const file = e.currentTarget.files[0];
    var reader = new FileReader();
    reader.onload = function () {
      setFileState(true);
      const text = reader.result as string;
      const cols = text.replace(/[ |"]/g, "").trim().split("\n");
      var titleID = [];
      var contentID = [];
      for (var i = 0; i < cols.length; i++) {
        titleID[i] = cols[i].split(",")[0];
        contentID[i] = cols[i].split(",")[1];
      }
      setTitleID(titleID);
      setContentID(contentID);
    };
    reader.readAsText(file);
  };

  const startConvert = () => {
    if (serviceID === "") {
      alert("サービスIDを入力してください");
      return;
    }
    if (!fileState) {
      alert("ファイルを設定してください");
      return;
    }
    convertIDs(serviceID, props.dynamodb, titleID, contentID);
  };

  return (
    <div style={{ marginTop: "16px" }}>
      <div>
        サービスID(数字):{" "}
        <input type="text" onChange={(e) => setServiceID(e.target.value)} />
      </div>
      <input type="file" onChange={setData} />
      <button onClick={startConvert}>コンバート開始</button>
    </div>
  );
};

const convertIDs = async (
  serviceID: string,
  dynamodb: DynamoDB,
  inputTitleIDs: string[],
  inputContentIDs: string[]
) => {
  const converter = new IDConverter(dynamodb);
  const titleIDs = await converter.convertTitleIDs(serviceID, inputTitleIDs);
  const contentIDs = await converter.convertContentIDs(
    serviceID,
    inputContentIDs
  );
  var csvData = "";
  for (var i = 0; i < inputTitleIDs.length; i++) {
    csvData +=
      inputTitleIDs[i] +
      "," +
      titleIDs.get(inputTitleIDs[i]) +
      "," +
      inputContentIDs[i] +
      "," +
      contentIDs.get(inputContentIDs[i]) +
      "\r\n";
  }
  csvDonwload("mapping", csvData);
};

const csvDonwload = (fileName: string, csvData: string) => {
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([bom, csvData], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName + ".csv";
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };
  a.addEventListener("click", clickHandler, false);
  a.click();
};

class IDConverter {
  private readonly dynamodb: DynamoDB;

  constructor(dynamodb: DynamoDB) {
    this.dynamodb = dynamodb;
  }

  convertTitleIDs = async (serviceID: string, ids: string[]) => {
    const map = new Map<string, string>();
    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      const res = await this.dynamodb
        .query({
          TableName: "Products",
          ExpressionAttributeNames: {
            "#type": "Type",
          },
          ExpressionAttributeValues: {
            ":id": { S: id },
            ":type": { S: "dist_" + serviceID + "_title" },
          },
          KeyConditionExpression: "ID = :id and #type = :type",
        })
        .promise();
      if (res.Items?.length === 0) {
        continue;
      }
      res.Items!.forEach((item) => {
        map.set(item.ID["S"] as string, item.CMSID["S"] as string);
      });
    }
    return map;
  };

  convertContentIDs = async (serviceID: string, ids: string[]) => {
    const map = new Map<string, string>();
    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      const res = await this.dynamodb
        .query({
          TableName: "Products",
          IndexName: "ContentIDIndex",
          ExpressionAttributeNames: {
            "#type": "Type",
          },
          ExpressionAttributeValues: {
            ":id": { S: id },
            ":type": { S: "dist_" + serviceID + "_content" },
          },
          KeyConditionExpression: "ContentID = :id and #type = :type",
        })
        .promise();
      if (res.Items?.length === 0) {
        continue;
      }
      res.Items!.forEach((item) => {
        // サンプルで重複した作品が取れてしまうので、本編だけ処理
        if ((item.SupplyType["S"] as string) === "1") {
          map.set(item.ContentID["S"] as string, item.CMSID["S"] as string);
        }
      });
    }
    return map;
  };
}

export default CampaignCSV;
