import React from 'react';
import { DynamoDB } from 'aws-sdk'

import { TitleIDSearchFromEBook, TitleIDSearchFromCMS } from './title_id';
import {
  PermitIDSearchFromDistributionID,
  DistributionIDSearchFromPermitID,
  PermitIDSearchFromContentID,
} from './content_id';

import CampaignCSV from './campagin';

type Props = {
  readonly dynamodb: DynamoDB;
}

export const Console = (props: Props) => {
  return (
    <div style={{ padding: "12px" }}>
      <p><b>キャンペーンID変換</b></p>
      <CampaignCSV dynamodb={props.dynamodb} />
      <hr />
      <p><b>タイトルID検索</b></p>
      <TitleIDSearchFromEBook dynamodb={props.dynamodb} />
      <TitleIDSearchFromCMS dynamodb={props.dynamodb} />
      <hr />
      <p><b>配信許諾(コンテンツ)ID検索</b></p>
      <PermitIDSearchFromDistributionID dynamodb={props.dynamodb} />
      <DistributionIDSearchFromPermitID dynamodb={props.dynamodb} />
      <PermitIDSearchFromContentID dynamodb={props.dynamodb} />
    </div>
  );
}
